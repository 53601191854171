.top_nav_text_orange {
  color: #ff6413;
}

.dot-new-message {
  position: absolute;
  background-color: #ff6413;
  width: 10px;
  height: 10px;
  top: -3px;
  right: 5px;
  border-radius: 50%;
}